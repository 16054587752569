import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { commonConfig, commonHeading } from 'components/Hero/config';

const image = (
    <StaticImage
        src="../../../assets/images/dentists-common/dentists-hero.jpg"
        alt="Dentists"
        width={1200}
        height={848}
    />
);

export const heroV1config = ({ hero_1_title, hero_1_description, button_text, button_url }) => ({
    ...commonConfig,
    button: {
        ...commonConfig.button,
        text: button_text || commonConfig.button.text,
        url: button_url || commonConfig.button.url,
    },
    heading: { title: hero_1_title, ...commonHeading },
    text: hero_1_description,
    image,
});
