import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Heading } from 'components/Headings/Heading';
import { heroV4 } from './HeroV4.module.css';

const heroImg = {
    1: (
        <StaticImage
            src="../../../../assets/images/affordable-dentist/1-cost-effective-dentistry-near-you.jpg"
            alt="budget dental care nearby"
            width={800}
            height={559}
        />
    ),
    2: (
        <StaticImage
            src="../../../../assets/images/affordable-dentist/2-cheap-dentist-no-insurance.png"
            alt="find dentists without insurance"
            width={800}
            height={559}
        />
    ),
    3: (
        <StaticImage
            src="../../../../assets/images/affordable-dentist/3-low-cost-dental-care-with-insurance.png"
            alt="book a dentist who accepts your insurance"
            width={800}
            height={559}
        />
    ),
    4: (
        <StaticImage
            src="../../../../assets/images/affordable-dentist/4-dental-check-up.jpg"
            alt="get a dental exam in your city"
            width={800}
            height={559}
        />
    ),
    5: (
        <StaticImage
            src="../../../../assets/images/affordable-dentist/5-tooth-pain.jpg"
            alt="preventive dental care"
            width={800}
            height={559}
        />
    ),
    6: (
        <StaticImage
            src="../../../../assets/images/affordable-dentist/6-digital-x-rays.jpg"
            alt="modern dental technology and x-rays"
            width={800}
            height={559}
        />
    ),
    7: (
        <StaticImage
            src="../../../../assets/images/affordable-dentist/7-local-affordable-dentists.jpg"
            alt="affordable dentists near you"
            width={800}
            height={559}
        />
    ),
};

export const HeroV4 = ({ title, description, imgNumber, isJSONsource }) => {
    const config = {
        id: '',
        type: 'h2',
        align_desktop: 'center',
        align_mobile: 'center',
        title,
    };

    return (
        <div className={heroV4}>
            {heroImg[imgNumber]}
            <Heading blok={config} classes="mt-2 mb-1 mb-1--mobile mt-1--mobile" />
            {isJSONsource ? (
                <p
                    dangerouslySetInnerHTML={{ __html: description }}
                    className="txt-center c-black mt-1 mb-2 mb-1--mobile mt-1--mobile"
                />
            ) : (
                <p className="txt-center c-black mt-1 mb-2 mb-1--mobile mt-1--mobile">{description}</p>
            )}
        </div>
    );
};
